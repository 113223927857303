<template>
  <main class="modal-control" v-if="numModals">
    <Modal
      v-for="(modal, index) in modals"
      :key="modal.id"
      :modal="modal"
      :settings="settings(modal)"
      @close="close(modal, index)"
      :class="modal.class"
      :index="index"
      @open="open(modal, index, $el)"
      :view-id="`${modal.id}`"
    >
      <div class="modalTitle modal-icon" slot="header">
        <iconic name="lock1a" />
        <span class="modalTitleText">{{ modal.title }}</span>
      </div>
      <component :is="modal.component" :modal="modal" :data="modal" :index="index" v-bind="modal.bind"></component>
    </Modal>
  </main>
</template>

<script>
export default {
  methods: {
    close: function(modal, index) {
      this.modals.splice(index, 1);
    },
    open: function(modal, index, $el) {},
  },
  computed: {
    modals: function() {
      return this.$store.state.modal.items;
    },
    numModals: function() {
      return this.$store.state.modal.items.length;
    },
    settings: function() {
      return function(modal) {
        const msettings = modal.settings || {};
        return {
          ...msettings,
          opened: true,
        };
      };
    },
  },
};
</script>
